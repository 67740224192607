import { axios } from "./../plugins/axios";
export function getGoodsData(par) {
  return axios.request({
    params: {
      module: "app",
      action: "product",
      app: "product_info",
      ...par
    }
  });
}
export function getRecomm(par) {
  return axios.request({
    params: {
      module: "app",
      action: "product",
      app: "tip_list",
      ...par
    }
  });
}
