<template>
  <div>
    <div class="goodImg">
      <download class="down" />
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in imgs" :key="index">
          <img class="swiperImg" :src="image" />
        </van-swipe-item>
      </van-swipe>
      <div class="price">
        <div class="peiceDay">
          ￥<span class="peiceTxt">{{ goodsInfo.min_price }}</span
          >/天<img
            src="https://t.yhaocang.com/upload/h5_static/img/heikajiage.png"
            alt=""
          />
        </div>
        <div class="noVip">
          非黑卡价￥{{ goodsInfo.price }}/天<span>
            评估价￥{{ goodsInfo.costprice }}</span
          >
        </div>
        <div class="bagname">
          {{ goodsInfo.product_title }}
        </div>
        <div class="threeicon">
          <div class="one"><van-icon name="passed" /><span>正品保证</span></div>
          <div class="one"><van-icon name="passed" /><span>专业洗护</span></div>
          <div class="one">
            <van-icon name="passed" /><span>支付宝预授权</span>
          </div>
        </div>
      </div>
    </div>
    <div class="zhanwei"></div>
    <div class="recommend">
      <p class="tuijian">为你推荐</p>
      <div class="itemindex">
        <div
          class="item"
          v-for="(item, index) in recomm"
          :key="index"
          @click.stop="downloadApp"
        >
          <img class="goodsImg" :src="item.imgurl" alt="" />
          <div class="name">{{ item.product_title }}</div>
          <div class="vipPrice">
            ￥{{ item.vip_price }}/天<img
              class="blackCard"
              src="https://t.yhaocang.com/upload/h5_static/img/vip-icon.png"
              alt=""
            />
            <span>￥{{ item.price }}/天</span>
          </div>
          <!-- <div class="tag">
            <span>6折</span>
            <span>新包首租</span>
            https://t.yhaocang.com/upload/h5_static/img/vip-icon.png
          </div> -->
        </div>
      </div>
    </div>
    <div class="contain"></div>
    <div class="bottom-btn" @click.stop="downloadApp">立即查看</div>
  </div>
</template>
<script>
import { getGoodsData, getRecomm } from "./../../api/goods";
import download from "@/components/download";
// eslint-disable-next-line no-unused-vars
import { Swipe, SwipeItem } from "vant";
export default {
  components: {
    download
  },
  data() {
    return {
      imgs: [],
      goodsInfo: {},
      recomm: [],
      pro_id: ""
    };
  },
  created() {
    let pro_id = this.$route.query.pro_id;
    this.pro_id = pro_id;
    this.getData(); /* option.id */
    this.getRecommend();
  },
  methods: {
    async getData() {
      const res = await getGoodsData({ pid: this.pro_id });
      if (res.data.code == 200) {
        this.imgs = res.data.data.image;
        this.goodsInfo = res.data.data;
        console.log(this.goodsInfo, 93);
        console.log(this.imgs, 94);
      }
    },
    async getRecommend() {
      const res = await getRecomm({ pid: this.pro_id });
      if (res.data.code == 200) {
        this.recomm = res.data.data;
      }
    },
    downloadApp() {
      window.location.href =
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.miwang.yhsc";
    }
  }
};
</script>
<style lang="scss" scoped>
.goodImg {
  width: 750px;
  background: #fff;
  /deep/.download {
    border-bottom: 1px solid #eeeeee !important;
  }
  /deep/.van-swipe {
    height: 633px;
    width: 100%;
    .swiperImg {
      width: 750px;
      height: 633px;
    }
  }
  .price {
    width: 100%;
    height: 324rpx;
    background: #fff;
    padding: 0 18px;
    // margin: 0 18px;
    margin-top: 40px;
    .peiceDay {
      color: #ff0000;
      font-size: 28px;
      font-weight: bold;
      line-height: 21px;
      .peiceTxt {
        font-size: 40px;
      }
      img {
        width: 78px;
        height: 21px;
        margin-left: 10px;
        line-height: 21px;
      }
    }
    .noVip {
      font-size: 25px;
      color: #999999;
      margin: 15px 0;
      span {
        margin-left: 67px;
      }
    }
    .bagname {
      font-size: 33px;
      font-weight: bold;
      color: #333333;
      width: 689px;
      margin-bottom: 28px;
    }
    .threeicon {
      font-size: 25px;
      color: #999999;
      display: flex;
      margin-bottom: 30px;
      .one {
        display: flex;
        align-items: center;
        margin-right: 49px;
      }
    }
  }
}
.zhanwei {
  width: 100%;
  height: 21px;
  background: #f0f0f0;
}
.recommend {
  width: 750px;
  // padding: 0 12px;
  .tuijian {
    font-size: 35px;
    color: #333333;
    font-weight: bold;
    text-align: center;
    margin: 51px 0 31px;
  }
  .itemindex {
    display: flex;
    width: 750px;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 0 10px;
    // justify-content: space-between;
    // display: inline-block;
  }
  .item {
    width: 344px;
    height: 487px;
    border-radius: 14px;
    margin: 10px 10px;
    border: 1px solid #eeeeee;

    .goodsImg {
      height: 340px;
      width: 340px;
      border-radius: 14px;
      border: unset;
    }
    .name {
      width: 308px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      /*  margin: 19px 25px 18px 12px; */
      margin: 18px 10px 0;
      font-size: 25px;
      color: #999999;
    }
    .vipPrice {
      font-size: 30px;
      color: #ff0000;
      margin: 18px 10px 0;
      font-weight: bold;
      display: flex;
      align-items: center;
      .blackCard {
        width: 25px;
        height: 21px;
      }
      span {
        color: #999999;
        font-weight: 400;
        font-size: 28px;
      }
    }
    .tag {
      margin: 10px 0 30px;
      span {
        border: 1px solid #ff0000;
        height: 28px;
        text-align: center;
        line-height: 28px;
        color: #ff0000;
        margin-left: 12px;
        border-radius: 4px;
        padding: 0 10px;
        font-size: 21px;
      }
    }
  }
}
.contain {
  width: 100%;
  height: 500px;
  background-color: #fff;
}
.bottom-btn {
  width: 100%;
  height: 104px;
  background: linear-gradient(0deg, #282725 0%, #2d2d2b 100%);
  position: fixed;
  bottom: 0;
  z-index: 50;
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  line-height: 104px;
  letter-spacing: 4px;
}
</style>
